/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


html.ios {
  --ion-default-font: 'Roboto', sans-serif;
}

body {
  font-family: 'Roboto', sans-serif;

  @media (min-width: 768px) {
    background: transparent linear-gradient(90deg, #523A90 0%, #2B3D87 100%) 0% 0% no-repeat padding-box !important;
  }
}

.display-content {
  display: flex !important;
}
//Bordure en haut de la page
.head-bordure.degrade-coul {
  background: linear-gradient(to right, #009fe3 0%, #009fe3 5%, #009b9f 27%, #4bae32 40%, #8ebf21 53%, #feca00 66%, #f18513 77%, #e30519 89%, #e30519 100%);
}

.head-bordure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px !important;
  opacity: 1;
  z-index: 9999;
  min-height: 1px !important;
}



/* ********************* GET fonts weight italic ******************************* */
// $weights: 200, 300, 400, 600, 700, 900;
// $names: extra-light, light, regular, semi-bold, bold, black;
// $length: length($weights);

// @function font-weight-variation($names, $weights, $index) when (isnumber(extract($weights, $index))) and ($index > 0) {
//   .font-weight-variation($names, $weights, ($index - 1)); // decrement.

//   $name: extract($names, $index);
//   $weight: extract($weights, $index);

//   .font-${name} {
//     font-weight: $weight;

//     &.italic {
//       font-style: italic;
//     }
//   }
// }

// .font-weight-variation($names, $weights, $length);
@function font-weight($keyword) {
  $conversion-map: ( thin: 100, light: 300, regular: 400, medium: 500, bold: 700, blacky: 900 );

  @if not map-has-key($conversion-map, $keyword) {
    @error "Unsupported `font-weight` keyword `#{$keyword}`";
    @return null;
  }

  @return map-get($conversion-map, $keyword);
}

@mixin font-weight($keyword) {
  //font-weight: font-weight($keyword);
  .font-#{$keyword} {
    font-weight: font-weight($keyword);
  }
}

@include font-weight('thin');
@include font-weight('light');
@include font-weight('regular');
@include font-weight('medium');
@include font-weight('bold');
@include font-weight('blacky');



.text-bleu-clair {
  color: #6991F7;
}

.d-flex {
  display: flex;
}

ion-popover {
  --width: 289px;

  ion-list {
    ion-item {
      &:last-of-type {
        --border-color: transparent;
      }
    }
  }
}

.ion-page {
  @media (min-width: 768px) {
    max-width: 768px;
    margin: auto auto;
    max-height: 90vh;
  }
}

.desktop-wrapper {
  ion-toolbar {
    text-align: center;
  }

  ion-header {
    &.header-md:after {
      background: none;
    }
  }
}

ion-modal {

  ion-content {
    --background: #ffffff;

    ion-datetime {
      --background: #ffffff;
    }
  }
}

.ion-color-danger {
  --ion-color-base: #F05A5B !important;
}

//modal MAP
.modal-map {
  --width: 80%;
  --height: auto;
  --max-height: 98%;
  --min-height: 50%;
  text-align: center;
  --border-radius: 20px;

  ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    // --overflow: auto;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  h1 {
    margin-bottom: 25px;
  }

  button {
    margin-bottom: 20px;
    background: #F05A5B;
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .modal-map {
    --width: 42%;
  }
}

//modal customiser
.modal-custom {
  --width: 100%;
  --min-width: auto;
  --max-width: auto;
  --height: 100%;
  --min-height: auto;
  --max-height: auto;
  --overflow: hidden;
  --border-radius: 0;
  --border-width: 0;
  --border-style: none;
  --border-color: transparent;
  --background: var(--ion-background-color, #fff);
  --box-shadow: none;
  --backdrop-opacity: 0;
  //--border-radius: 20px 20px 0 0px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  contain: strict;
}

.loadingspinner {
  --color: #85effd;
}

.title-list {
  color: #37D5BA;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: 20px 10px 8px 10px;
  text-transform: uppercase;
}

ion-content {
  --background: #15154A;

  &.bg-light {
    --background: #F6F8FA 0% 0% no-repeat padding-box;
  }
}

ion-list {
  &.custom-list {
    background: transparent;

    ion-item:not(.search) {
      --padding-start: 0px;
      --inner-padding-end: 0;
      --border-color: none;
      display: flex;
      align-items: inherit;
      --border-radius: 6px;

      &:not(.solution) {
        --background: transparent linear-gradient(90deg, #523A90 0%, #2B3D87 100%) 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 3px 6px #00000042;
      }

      &:not(:first-of-type) {
        padding-top: 10px;
      }

      &.bg-transparent-item {
        --background: transparent !important;
        box-shadow: none;
      }

      .infos {
        text-align: center;
        background: #ffffff;
        border-radius: 10px 0 0 10px;
        color: #6991F7;
        padding: 10px;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;

        &.isActive {
          color: #02cdba;
        }

        h6 {
          font-size: 12px;
          margin-top: 5px;
          margin-bottom: 5px;
          font-weight: 900;

          &.date {
            color: #F15A5B;
          }
        }

        p {
          font-size: 10px;
        }
      }

      &.isLive {
        .infos {
          background: #F15A5B;
          color: #ffffff !important;
        }
      }

      &.isClosed {
        --background: transparent linear-gradient(90deg, #372761 0%, #1E2B61 100%) 0% 0% no-repeat padding-box !important;

        .infos {
          background: transparent;
          color: #7A7AAC !important;
        }
      }

      ion-label {
        .ent {
          color: #85EFFD;
        }
      }

      .ion-lab {
        margin: 0;
        padding: 10px;
        padding-right: 0;
        flex: 1;
        white-space: normal;

        .flag {
          width: 23px !important;
        }

        h6 {
          color: #AAAFCD;
          font-size: 10px;

          &.tiret {
            line-height: 1;
          }
        }

        .title {
          font-size: 16px;
          color: #F0F7FF;
        }

        .location {
          color: #85EFFD;
          font-size: 10px;
          font-weight: 900;
          text-transform: uppercase;

          svg {
            vertical-align: middle;
          }
        }
      }

      .end-bloc {
        padding: 10px 10px 10px 5px;
        align-self: center;

        .event-closed {
          font-size: 10px;
          font-weight: 900;
          color: #6991F7;
          height: auto;
        }

        .toggle-favorite {
          display: flex;
          flex-direction: column;
          align-items: center;


          ion-toggle {
            --handle-background-checked: #fff url(/assets/images/pictos/bell.svg) no-repeat center / 50%;
            --handle-background: #fff url(/assets/images/pictos/bell.svg) no-repeat center / 50%;
            --handle-max-height: none;
            --handle-height: 32px;
            --handle-width: 32px;
            width: 45px;
            --background-checked: #f05a5b;
            --background: #161643 0% 0% no-repeat padding-box;
            /*--box-shadow: 0px 3px 6px #f15a5bd6;*/
            padding-left: 0px;
            padding-right: 0;
            margin-bottom: 10px;
          }

          .link-favorite {
            display: block;
          }
        }
      }

      &.isLive, &.isClosed {
        .end-bloc {
          align-self: start;
        }
      }
    }
  }
}

ion-avatar {
  width: 60px;
  height: 60px;
  background: linear-gradient(to right, #009fe3 0%, #009fe3 5%, #009b9f 27%, #4bae32 40%, #8ebf21 53%, #feca00 66%, #f18513 77%, #e30519 89%, #e30519 100%);
  position: relative;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  .module {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: var(--border-radius);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    margin-top: 11px;
    font-size: 30px;
    text-align: center;
  }
}

.link-favorite {
  cursor: pointer;

  .star-fav {
    filter: none;
    fill: #161643
  }
  /*svg {
    g {
      filter: none;
    }

    path {
      fill: #161643
    }
  }*/
  &.isFavorite {
    .star-fav {
      filter: url(#shadow);
      fill: #fdfdfd;
    }
    /*svg {
      g {
        filter: url(#shadow);
      }

      path {
        fill: #fdfdfd;
      }
    }*/
  }
}
//custom alert cookies
.custom-alert {
  // max-width: 378px ;
  &.alert-cookies {
    margin-top: 105px;
  }

  .alert-wrapper {
    padding: 50px;
    max-width: 90%;
    --background: #ffffff;

    .alert-head, .alert-message {
      text-align: left;
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
      color: #15154A;

      h2 {
        font-size: 16px;
        color: #15154A;
        margin-bottom: 20px;
      }
    }

    .alert-button-group {
      justify-content: center;
      margin-top: 10px;
      flex-direction: column;
    }

    .btn-link {
      font-size: 10px;
      border: none;
      color: #6991F7;
      font-weight: normal;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .btn-alert {
      flex: 0 auto;
      border: none;
      color: white;
      border-radius: 30px;
      padding: 15px;
      height: auto;
      font-size: 16px;
      font-weight: 700;
      min-width: 100%;
      --justify-content: center !important;
      justify-content: center !important;
      text-align: center !important;
      /*.alert-button-inner {
        justify-content: center;
      }*/
      &.btn-accept {
        background: #F05A5B;
      }

      &.btn-cancel {
        background: #665CB4;
        margin-top: 25px;
      }

      .alert-button-inner.sc-ion-alert-md {
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
  }

  &.alert-logged {
    .alert-head, .alert-message {
      text-align: center;
    }

    .alert-message {
      color: #132046;

      .textHeader {
        color: #15154A;
        font-size: 28px;
        font-weight: 900;
        padding-bottom: 21px;
      }
    }
  }
}

.color-red {
  color: #F05A5B !important;
}

.special-list {
  //padding: 0;
  padding-left: 1rem;
  margin-bottom: 0;

  > li {
    @font-medium;
    line-height: 1.3em;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &::marker {
      content: "→ "
    }
  }
}
// ==> map fixe en bas des pages detail
.imgMapPageDetail {
  position: fixed;
  bottom: 0;
  z-index: 0;
  background: #F6F8FA;
  //background: linear-gradient(to bottom, transparent 0%, #F6F8FA 20%);
  padding-top: 10px;

  @media (min-width: 768px) {
    bottom: -120px;
    cursor: pointer;
  }

  .parentImg {
    position: relative;


    .transparenceImg {
      background: linear-gradient(to bottom, transparent, transparent);
      height: 100px;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.custom-btn {
  padding: 0 20px;
  min-width: 216px;
  min-height: 53px;
  height: auto;
  border-radius: 30px;
  font-size: 16px;
  color: #FFFFFF;
  text-transform: uppercase;
  transition: all 0.4s;
  font-weight: 700;

  &.with-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon {
      &.right {
        margin-left: 10px;
      }
    }
  }
}
/***CUSTOM INPUT SEARCH***/
.search {
  --background: #15154A !important;
  color: #ffffff;
  padding-top: 5px;

  .custom-search {
    .searchbar-search-icon {
      left: auto;
      top: 5px;
      width: 25px;
      height: 25px;
      right: 10px;
      --icon-color: white;
    }

    .searchbar-input-container {
      max-width: 340px;
      width: 100%;
      /* min-width: 170px;*/
    }


    .searchbar-input {
      background: #362E75 0% 0% no-repeat padding-box;
      border-radius: 20px;
      color: #fff;
      padding-left: 25px;
      padding-right: 60px;
      --placeholder-color: #362E75;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .searchbar-clear-button {
      right: 37px;

      .searchbar-clear-icon {
        color: #F05A5B;
      }
    }
  }

  .title {
    font-size: 23px;
    font-weight: bold;
    margin-right: 45px;
    width: 100%;
  }

  .filter {
    font-size: 22px;
    border-radius: 50%;
    width: 50px;
    height: 40px;
    text-align: center;
    margin-left: 10px;
  }
}
//Caché la barre de scroll menu horizontal
::-webkit-scrollbar, *::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}
//Custom pagination Slides dans exhibitors-detail
/*.swiper-pagination {
  bottom: -3px !important;
}
*/
// ==> Page detail GLOBAL SCSS
.detail-page {
  .contenu {
    background: transparent linear-gradient(90deg, #523A90 0%, #2B3D87 100%) 0% 0% no-repeat padding-box;
    display: flex;
    padding-top: 45px;

    .content-info {
      flex: 1;

      .presentation {
        color: #f2f2fd;
        font-size: 14px;
        padding: 20px;
        padding-top: 0;
        margin-bottom: 0;
      }

      .categories {
        padding: 10px 16px 25px 16px;

        .special-list {
          > li {
            font-size: 10px;
            color: #ccccdbbf;
          }
        }
      }
    }

    .favorite {
      padding: 0 10px;
    }
  }

  .btn-brochure {
    background: #85EFFD 0% 0% no-repeat padding-box;
    border-radius: 16px;
    padding: 5px 14px;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #423C8D;
    text-decoration: none;
  }

  .new {
    font-size: 12px;
    color: #f05a5b;
    font-weight: 900;
    align-self: baseline;
  }

  .share {
    border-radius: 50%;
    width: 41px;
    height: 41px;
    /*position: absolute;*/
    background-color: #f05a5b;
    position: absolute;
    bottom: 45px;
    right: 15px;

    ion-icon {
      color: #ffffff;
      font-size: 30px;
      padding: 6px;
    }
  }

  .social-buttons-bloc {
    position: absolute;
    bottom: 45px;
    right: 15px;

    &.with-img {
      bottom: 0;
    }

    .social-btn {
      border-radius: 50%;
      width: 41px;
      height: 41px;
      /*position: absolute;*/
      background-color: #f05a5b;
      display: flex;
      align-items: center;
      justify-content: center;

      &.transparent {
        background-color: transparent;
      }

      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }

    .btn-soc {
      display: flex;

      img {
        width: auto;
      }

      &.link-btn {
        fill: #85effd;
      }

      &.fav-btn {
        .star-fav-detail {
          fill: #7B2C2D; //new
        }

        &.isFavorite {
          .star-fav-detail {
            fill: #ffffff;
          }
        }

        img {
          height: 16.81px;
        }
      }

      &.share-btn {
        img {
          height: 20.88px;
        }
      }
    }
  }

  ion-item {
    &.info-exhib {

      .titre {
       // margin-right: auto;
        margin: auto;
        padding-left: 16px;
        margin-bottom: 51px;
        h2 {
          color: #ffffff;
        }

        .loc {
          font-weight: 900;
          font-size: 11px;
          color: rgb(133 239 253);
        }
      }
    }
  }
  //==> ION SLIDES GLOBAL
  .swiper-container {
    overflow: visible;
  }

  .swiper-wrapper {
    padding-bottom: 13px;
  }

  .swiper-pagination {
    bottom: -17px !important;

    .swiper-pagination-bullet {
      background: #7A7AAC;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: #161643;
    }
  }

  .bloc-slides {
    ion-slide {
      height: auto;
      align-items: inherit;

      ion-card {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 100%;
        margin: 0;
        box-shadow: 0px 6px 12px #0000000f;
        display: flex;
        flex-direction: column;

        .img-slider {
          height: 111px;
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &.related-exhi {
          .img-slider {
            background-size: 80% auto;
          }
        }

        ion-card-header {
          padding: 9.5px 6px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          flex: 1;

          .name-card {
            width: 100%;
            text-align: start;

            ion-card-subtitle {
              color: #161643;
              font-size: 14px;
              font-weight: bold;
            }
          }

          .arrow {
            /*width: 13.5px;*/
            width: 20%;
            text-align: end;

            @media (min-width: 768px) {
              cursor: pointer;
            }

            img {
              width: 13.5px;
            }
          }

          &.gradient-blue {
            background: transparent linear-gradient(90deg, #523A90 0%, #2C3D88 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 12px #0000000F;

            ion-card-subtitle {
              color: #fff;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .section {
    padding: 15px 13px;
    background: #F6F8FA 0% 0% no-repeat padding-box;


    &.big-pad-bot {
      padding-bottom: 40px; //80px
    }

    .title {
      color: #272D54;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 13px;
    }

    &.bg-dark-blue {
      background: #15154A;

      .title {
        color: #fff;
      }

      .swiper-pagination-bullet-active {
        background: #85effd;
      }
    }

    .slides {
      margin-top: 13px;
    }

    .custom-list {
      ion-item {

        infos {
          h6 {
            color: #161643;

            &.date {
              color: #F15A5B;
            }
          }
        }

        ion-label {
          h6 {
            color: #AAAFCD; //#C7C7C7;
          }

          h3 {
            color: #161643;
            font-size: 16px;
            font-weight: 500;
          }

          .location {
            color: #6991F7;
            margin: 0;
          }
        }

        &.bg-transparent-item {
          /*--background: transparent !important;
          box-shadow: none;*/
          ion-label {
            font-weight: 500;

            h3 {
              color: #282E56;
              font-size: 14px;
            }

            p {
              color: #282E56;
              font-size: 12px;
            }
          }

          .end-bloc {
            align-self: self-start;
          }

          .isFavorite {
            .star-fav {
              fill: #6991F7;
            }
          }
        }

        &.bg-white-item {
          --background: #ffffff !important;
          border-radius: 6px;
          box-shadow: 0px 3px 6px #00000016;

          .infos {
            border-right: 1px solid #C7C7C738;
            background: transparent;
            border-radius: 0;
          }

          .isFavorite {
            .star-fav {
              fill: #6991F7;
            }
          }
        }
      }
    }

    .link-contact {
      font-size: 12px;
      line-height: 2;
      font-weight: 500;


      a {
        text-decoration: none;
        color: #6991F7;
      }

      p {
        color: #1E1F5C;
      }
    }
  }
  // ==> Speakers
  .speak-expert {
    .list-md {
      background: transparent;

      ion-item {
        --padding-start: 0;
        padding-top: 22px;
        --background: transparent;
      }
    }
  }
}

.infos-pratiques {
  a {
    color: #523A90;
    display: inline-block;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  table {
    width: 100%;
  }

  img {
    height: auto !important;
    max-width: 100%;
  }
}

.pages-other {
  .contenu {
    padding-bottom: 200px;
  }

  ion-list {
    &.custom-list {
      padding-top: 25px;
      background: #15154a;
      margin: 0 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > div {
        &.bg-img {
          background-color: #ffffff;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 7px;
          margin-bottom: 14px;
          width: 46%;
          margin: 2%;
        }
      }

      ion-item {
        padding-top: 69px;
        color: #ffffff;
        --border-color: none;
        --border-radius: 0 0 6px 6px;
        --border-style: none;
        align-items: center;

        p {
          font-size: 8px;
          font-weight: 500;
          margin-bottom: 0;
        }

        ion-label {
          margin: 10px;

          h6 {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
          }

          p {
            color: #6991F7;
          }
        }

        .end-bloc {
          align-self: start;

          .show-map {
            text-align: center;

            p {
              color: #85EFFD;
            }
          }
        }
      }

      &.list-full-item {
        > div {
          &.bg-img {
            width: 100%;
          }
        }

        ion-item {
          padding-top: 165px;

          ion-label {
            margin: 20px;
          }

          .end-bloc {
            padding: 20px 20px 20px 0;
          }
        }
      }
    }
  }
}

.custom-form {
  .input {
    display: block;
    background: #ffffff;
    font-size: 14px;
    line-height: 1.2;
    border: none;
    outline: none;
    height: 35px;
    padding: 0 20px 0 10px;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 4px;
  }

  ion-item {
    --border-color: transparent;
    --background: transparent;
    margin-bottom: 15px;
    --padding-start: 0;
    --padding-end: 0 !important;
    --padding: 0 !important;
    width: 80%;
    margin: 15px auto;

    ion-input {
      background: #ffffff;
      box-shadow: inset 0px 1px 3px #00000029;
      --padding-start: 10px !important;
      --placeholder-color: #C7C7C7;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
    }
  }

  .container-form-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .custom-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f05a5b;
      width: min-content;

      &.cancel {
        margin: 20px 0;
        background: #665CB4;
      }
    }
  }

  .links {
    font-size: 10px;
    text-align: center;

    a {
      color: #6991F7;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 1.7;
    }
  }
}

ion-tabs {
  z-index: 5;
  position: relative;
}

ion-toast {
  &.toast-send {
    --background: white !important;
    --color: #FF4646;
    --width: 100%;
    --start: 0;
    --border-radius: 30px 30px 0 0;
    --min-height: 70px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    --button-color: #FF4646;
    z-index: 0 !important;
    transform: translateY(-87px) !important;

    &::part(button) {
      font-size: 20px;
      width: 66px;
      text-align: center;
      height: 66px;
      padding-left: 0;
    }
  }
}

.toast-custom {
  --background: transparent linear-gradient(90deg, #523A90 0%, #2B3D87 100%) 0% 0% no-repeat padding-box !important;
  --box-shadow: 0px 6px 12px #00000029;
  --width: 85%;
  color: white;
  font-size: 24px;
  text-align: center;
}

ion-infinite-scroll-content {
  margin-top: 15px;
}

@media (min-width: 768px) {
  a {
    &:hover {
      &:not(.btn-brochure) {
        text-decoration: underline !important;
      }
    }
  }

  .hall-link {
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: #6991f7 !important;

      .hall {
        color: #6991f7 !important;
      }

      svg use {
        fill: #6991f7 !important;
      }
    }
  }

  .social-btn {
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  ion-label {
    > h2, h6:not(.text-uppercase), .title, h3 {
      cursor: pointer;
    }
  }

  .btn-back {
    &:hover {
      opacity: 0.9 !important;
    }
  }

  .show-map, .calendar-btn {
    cursor: pointer;
  }
}

html.plt-ios.plt-pwa {
  height: 99.9vh !important;
}

.customNotif{
  /*--background: rgba(106, 95, 193) !important;*/
  --background: transparent linear-gradient(90deg, #9171e1 0%, #2B3D87 100%) 0% 0% no-repeat padding-box !important;
  --color: white;
  // .imageNotif{
  //   --width: 50px !important;
  //   position: absolute;
  //   right: 10px;
  //   top: 9px;
  // }

 
}